import React from "react";
import styled from "styled-components";

import Main from "../components/Main";

import { PageHeader } from "../components/Typo";
import Section from "../components/Section";

import { Contact } from "../svg";

import Input from "../components/Input";
import TextField from "../components/TextField";

const Form = styled.form`
  margin-top: 48px;
`;

const Submit = styled.button`
  display: block;
  margin: 0 auto;
  margin-top: 48px;
  border: 0;
  outline: 0;
  padding: 12px 24px;
  color: white;
  font-weight: 400;
  text-transform: uppercase;
  background-color: var(--primary);
  display: flex;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;

  & > svg {
    margin-left: 8px;
    fill: white;
  }

  &:hover {
    background-color: #3c987b;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

const ErrorMessage = styled.div`
  font-size: 12px;
  color: #d23e3e;
`;

const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const initialValues = {
  name: "",
  email: "",
  subject: "",
  message: "",
};

const errorMessages = {
  required: "Dit veld is verplicht",
  invalidEmail: "Dit is geen geldig e-mailadres",
};

function ContactForm() {
  const [state, setState] = React.useState({
    send: false,
    errors: {},
    values: initialValues,
  });

  function handleSend(evt) {
    evt.preventDefault();

    const errors = {};

    const { name, message, subject, email } = state.values;

    if (!name) {
      errors.name = "required";
    }
    if (!message) {
      errors.message = "required";
    }
    if (!email) {
      errors.email = "required";
    } else if (!emailPattern.test(email)) {
      errors.email = "invalidEmail";
    }
    const hasErrors = Object.keys(errors).some((key) => Boolean(errors[key]));

    if (hasErrors) {
      setState({
        ...state,
        errors,
        send: false,
      });
      return;
    }

    fetch(`/api/contact`, {
      method: "POST",
      body: JSON.stringify(state.values),
      headers: {
        "Content-Type": "application/json",
      },
    });

    setState({ ...state, values: initialValues, errors: {}, send: true });
  }

  function handleChange(field) {
    return function(evt) {
      setState({
        ...state,
        values: {
          ...state.values,
          [field]: evt.target.value,
        },
        errors: {
          ...state.errors,
          [field]: null,
        },
      });
    };
  }

  if (state.send) {
    return (
      <>
        <h2 style={{ textAlign: "center", marginTop: 50 }}>Verzonden! 👍🏼</h2>

        <p style={{ textAlign: "center" }}>
          Bedankt voor uw bericht. Ik neem zo spoedig mogelijk contact met u op!
        </p>
      </>
    );
  }

  return (
    <Form onSubmit={handleSend}>
      <Input
        style={{ marginTop: 0 }}
        value={state.values.name}
        onChange={handleChange("name")}
        placeholder="Voor- en achternaam"
      />
      {state.errors.name && (
        <ErrorMessage>{errorMessages[state.errors.name]}</ErrorMessage>
      )}
      <Input
        value={state.values.email}
        onChange={handleChange("email")}
        placeholder="E-mailadres"
      />
      {state.errors.email && (
        <ErrorMessage>{errorMessages[state.errors.email]}</ErrorMessage>
      )}
      <Input
        value={state.values.subject}
        onChange={handleChange("subject")}
        placeholder="Onderwerp"
      />
      {state.errors.subject && (
        <ErrorMessage>{errorMessages[state.errors.subject]}</ErrorMessage>
      )}
      <TextField
        value={state.values.message}
        onChange={handleChange("message")}
        placeholder="Bericht"
      />
      {state.errors.message && (
        <ErrorMessage>{errorMessages[state.errors.message]}</ErrorMessage>
      )}

      <Submit>
        Versturen <Contact width={18} />
      </Submit>
    </Form>
  );
}

export default () => {
  return (
    <Main title="Contact" path="/contact">
      <Section
        id="form"
        style={{ maxWidth: 600, margin: "0 auto", marginTop: 96 }}
      >
        <PageHeader
          center
          title="Contact"
          icon="contact"
          style={{ marginBottom: 16 }}
        />

        <p style={{ textAlign: "center", marginTop: 32 }}>
          Neem voor boekingen contact op via{" "}
          <a href="mailto:info@emmalou.nl">info@emmalou.nl</a>
        </p>

        <ContactForm />
      </Section>
    </Main>
  );
};
