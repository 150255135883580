import styled from "styled-components";
import media from "styled-media-query";

const Section = styled.section`
  margin: 0 auto;
  padding: 0px 16px;
  margin-top: ${p => (p.noMargin ? 0 : 128)}px;
  ${p => p.flex && "display: flex;"}

  ${media.lessThan("medium")`
    flex-direction: column;
    margin-top: ${p => (p.noMargin ? 0 : 64)}px;
  `}

  max-width: 960px;
`;

export default Section;
