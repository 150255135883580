import styled from "styled-components";

const Input = styled.input`
  width: 100%;
  padding: 16px 16px;

  border: 0;
  outline: 0;
  border-radius: 0px;
  box-sizing: border-box;

  border-bottom: 1px dotted #636363;

  font-family: Livvic;

  margin-top: 16px;

  &:focus {
    border-bottom: 1px solid #333;
  }
`;

export default Input;
